@import "mixins/browser-targeting";

/* ----- vendor ----- */

// reset
@import 'vendor/reset';

/* ----- custom ----- */

// 00 variables
@import 'partials/giveba-00-variables';

// 05 fonts
@import 'partials/giveba-05-fonts';

// 10 global styles
@import 'partials/giveba-10-global';

// 12 Buttons
@import 'partials/giveba-12-button';

// 15 slick-slider
@import 'partials/giveba-15-slick-slider';

// 20 wrapper
@import 'partials/giveba-20-warpper';

// 30 home-navigation
@import 'partials/giveba-30-home-navigation';

// 30 subpage-navigation
@import 'partials/giveba-35-subpage-navigation';

// 40 footer
@import 'partials/giveba-40-footer';

// 50 projekte
@import 'partials/giveba-50-projekte';

// 60 ueber uns
@import 'partials/giveba-60-ueber-uns';

// 70 referenzen
@import 'partials/giveba-70-referenzen';

// 71 referenzen map confirm
@import 'partials/giveba-71-referenzen-map';

// 80 Kontakt
@import 'partials/giveba-80-kontakt';

// fancybox
@import './jquery.fancybox.min.css';